import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OutputContainerService } from '@app/modules/run-phase/services';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { notificationTopic } from '@app/shared/utils';
import { LoadingNotificationService } from 'chronos-shared';
import { finalize } from 'rxjs/operators';
import { GluingService } from '@app/modules/run-phase/services/gluing/gluing.service';
import { elastic } from '@app/shared/utils/elastic';
import { ManualMachineMode, OuterQuantityDetails, Quantity } from 'chronos-core-client';
import { ProductionOrderDsService } from '@app/core/data-services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pallet-quantity-change',
  templateUrl: './output-quantity-change.component.html',
  styleUrls: ['./output-quantity-change.component.scss']
})
export class OutputQuantityChangeComponent implements OnInit, OnDestroy {
  public readonly LOADING_TOPIC = notificationTopic.modalChangeOutputQuantity;
  public readonly SUGGEST_QUANTITY_LOADING_TOPIC = notificationTopic.suggestQuantity;
  public readonly INPUT_STYLE = { textAlign: 'right', width: '175px' };

  public modalForm: UntypedFormGroup;
  public changeFollowingPallets = false;
  public isChangeFollowingPalletsEnabled = false;
  public articleClassification;
  public quantity: Quantity;
  public isOuterView: boolean;
  public isLastContainer: boolean;
  public disableQuantityInput: boolean;
  public numberofPartialOrFullPallet: number;
  public numberofOuters: number;
  public numberofPiecesPerOuter: number;
  public numberofPiecesInRestOuter: number;

  private readonly INPUT_MAX_QUANTITY = 9999999;
  private readonly INPUT_MIN_QUANTITY = 1;

  private productionOrderId: number;
  private producedMaterialId: number;
  private isUnit: boolean;
  private manualMachineMode: ManualMachineMode;
  private targetQuantity: Quantity;
  private subscriptions = new Subscription();

  constructor(
    private outputContainerService: OutputContainerService,
    private gluingService: GluingService,
    private productionOrderService: ProductionOrderDsService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  public ngOnInit(): void {
    this.initModalForm();
    this.initModalData();

    this.modalForm.get('quantity')?.valueChanges.subscribe((value) => {
      this.onQuantityChange(value);
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public closeModal(): void {
    this.ref.close();
  }

  public submitModal(): void {
    if (this.modalForm.valid) {
      if (this.isUnit) {
        this.setOutputUnitQuantity();
      } else {
        if (this.producedMaterialId) {
          this.setParticularContainerQuantity();
        } else {
          this.setOutputPoolContainerQuantity();
        }
      }
    } else {
      this.modalForm.markAllAsTouched();
    }
  }

  public onSuggestQuantity(): void {
    LoadingNotificationService.publish(this.SUGGEST_QUANTITY_LOADING_TOPIC, true);
    this.disableQuantityInput = true;
    this.productionOrderService
      .suggestQuantityForLastProducedMaterial(this.productionOrderId)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.SUGGEST_QUANTITY_LOADING_TOPIC, false);
          this.disableQuantityInput = false;
        })
      )
      .subscribe((result) => {
        this.modalForm.controls.quantity.setValue(result.containerQuantity.value);
      });
  }

  private initModalForm(): void {
    this.modalForm = this.modalForm = new UntypedFormGroup({
      quantity: new UntypedFormControl('', [
        Validators.required,
        Validators.min(this.INPUT_MIN_QUANTITY),
        Validators.max(this.INPUT_MAX_QUANTITY)
      ])
    });
  }

  private initModalData(): void {
    this.productionOrderId = this.config.data?.changeQuantity?.productionOrderId;
    this.isUnit = this.config.data?.isUnit;
    this.manualMachineMode = this.config.data?.manualMachineMode;
    this.producedMaterialId = this.config.data?.changeQuantity?.producedMaterialId;
    this.quantity = this.config.data?.changeQuantity?.quantity;
    this.articleClassification = this.config.data?.articleClassification;
    this.isOuterView = this.config.data?.isOuterView;
    this.isLastContainer = this.config.data?.isLastContainer;
    this.targetQuantity = this.config.data?.targetQuantity;

    if (this.quantity) {
      this.modalForm.controls.quantity.setValue(this.quantity.value);
    }

    this.isChangeFollowingPalletsEnabled =
      this.producedMaterialId &&
      !this.isUnit &&
      this.manualMachineMode !== ManualMachineMode.MANUAL_WORKING_PLACE &&
      this.manualMachineMode !== ManualMachineMode.SORTING;

    if (this.isOuterView) {
      this.subscriptions.add(
        this.productionOrderService.getOuterViewQuantityDetails(this.productionOrderId).subscribe((outerViewDetails) => {
          this.manageOuterQuantityDetailValues(outerViewDetails);
        })
      );
    }
  }

  private setOutputUnitQuantity(): void {
    const trace = elastic.traceUiActivity('OutputQuantityChangeComponent.setOutputUnitQuantity');
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);

    const quantityValue = this.modalForm.controls.quantity.value;
    this.gluingService
      .changeQuantityOfOutputUnit(this.productionOrderId, quantityValue)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
          trace.end();
        })
      )
      .subscribe(() => {
        this.closeModal();
      });
  }

  private setOutputPoolContainerQuantity(): void {
    const trace = elastic.traceUiActivity('OutputQuantityChangeComponent.setOutputPoolContainerQuantity');
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);

    const quantityValue = this.modalForm.controls.quantity.value;
    this.outputContainerService
      .setOutputPoolContainerQuantity(this.productionOrderId, quantityValue)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
          trace.end();
        })
      )
      .subscribe(() => {
        this.closeModal();
      });
  }

  private setParticularContainerQuantity(): void {
    const trace = elastic.traceUiActivity('OutputQuantityChangeComponent.setParticularContainerQuantity');
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);

    const quantityUnitId = this.config.data?.changeQuantity?.quantity?.unitId;
    const quantityValue = this.modalForm.controls.quantity.value;
    this.outputContainerService
      .changeContainerQuantity(this.producedMaterialId, { value: quantityValue, unitId: quantityUnitId }, this.changeFollowingPallets)
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
          trace.end();
        })
      )
      .subscribe(() => {
        this.closeModal();
      });
  }

  private onQuantityChange(quantity: number) {
    if (this.isOuterView) {
      this.productionOrderService.getOuterViewQuantityDetails(this.productionOrderId).subscribe((outerViewDetails) => {
        this.targetQuantity.value = quantity;
        this.manageOuterQuantityDetailValues(outerViewDetails);
      });
    }

    return;
  }

  private manageOuterQuantityDetailValues(outerQuantityDetails: OuterQuantityDetails): void {
    const piecesPerOuter = outerQuantityDetails.piecesPerOuter || 1;
    const targetQnt = this.targetQuantity.value;
    const outerQuantity = outerQuantityDetails.quantityPerPallet.value || 1;

    this.numberofPartialOrFullPallet = Math.ceil(targetQnt / outerQuantity);
    this.numberofOuters = Math.floor(targetQnt / piecesPerOuter);
    this.numberofPiecesPerOuter = piecesPerOuter;
    this.numberofPiecesInRestOuter = targetQnt - Math.floor(targetQnt / piecesPerOuter) * piecesPerOuter;
  }
}
